import { FC, useEffect, useState } from 'react';
import styles from './Desktop.module.less';
import { Layout, Menu, Space, Grid } from 'antd';
import { Button as ButtonAntd } from 'antd';
import clsx from 'clsx';
import { TFunction, useTranslation } from 'react-i18next';
import { faChevronDown, faUser, faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { useAppDispatch, useAppSelector, useToggle } from 'hooks';
import { useMemo } from 'react';
import { LanguageSelect } from 'components/LanguageSelect';
import Link from 'next/link';
import { accountRoutes, navigationRoutes } from './router';

import SettingOutlined from '@ant-design/icons/lib/icons/SettingOutlined';
import { PopupTradeSetting } from '../PopupTradeSetting';
import { QuickOrder } from '../QuickOrder';
import { routes } from 'types/routes';
import { UserProfile, useUser } from '@auth0/nextjs-auth0';
import { useRouter } from 'next/router';
import { logout } from 'utils/auth';
import { Avatar } from 'components/Avatar';
import { setAuthModal } from 'store/ducks/system/slice';
import { SubAccountDropdown } from 'components/SubAccountDropdown';
import { useGetUserInfo } from 'api/account';
import { useTheme } from 'next-themes';
import store from 'store';
import Candle from 'assets/svgs/components/Candle';
import LightBulb from 'assets/svgs/components/LightBulb';
import { ThemeSwitch } from 'components/ThemeSwitch';
import Hide from 'components/Hide';
import NotificationModal from 'modules/NotificationPage/components/NotificationModal';
import NotificationDropdown from 'modules/NotificationPage/components/NotificationDropdown';
import { getNotificationStore } from 'store/ducks/notification/slice';

const { useBreakpoint } = Grid;
const { SubMenu } = Menu;

// const getWalletMenu = (t: TFunction<'translation'>) => (
//   <Menu>
//     {walletRoutes.map((wallet) => (
//       <Menu.Item key={wallet.title}>
//         <Link key={wallet.title} href={wallet.path}>
//           <a id={wallet.path}>{t(wallet.title)}</a>
//         </Link>
//       </Menu.Item>
//     ))}
//   </Menu>
// );

const getAccountMenu = (t: TFunction<'translation'>, user?: UserProfile, kyc?: boolean) => (
  <Menu>
    <Menu.Item key={user?.email} icon={<img src="/images/svgs/arrow-from-bottom-solid.svg" alt="icon" />}>
      <Link href={routes.dashboard}>
        <a>
          <div className={styles.userEmail}>
            {kyc && <img src="/images/svgs/badge-check-duotone.svg" alt="icon" />}
            <div>{user?.email}</div>
          </div>
          {/* {nickname && (
            <div className={styles.nickNameText}>
              <small>
                {t('sub_account.sub')} {nickname}
              </small>
            </div>
          )} */}
        </a>
      </Link>
    </Menu.Item>
    <Menu.Divider />
    {accountRoutes.map((account) => (
      <Menu.Item disabled={account.disabled} key={account.title} icon={<img src={account.icon} alt="icon" />}>
        <Link href={account.path}>{t(account.title)}</Link>
      </Menu.Item>
    ))}
    <Menu.Item
      key="navbar.account.log_out"
      onClick={logout}
      icon={<img src="/images/svgs/power-off-solid.svg" alt="power-off" />}
    >
      {t('navbar.account.log_out')}
    </Menu.Item>
  </Menu>
);

const getNavList = (t: TFunction, user?: UserProfile) => {
  return (
    <>
      {navigationRoutes.map((route) => {
        if (route.path) {
          if (route.path === routes.buy && !user) {
            return (
              <Menu.Item key={route.path}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    store.dispatch(
                      setAuthModal({
                        type: 'auth',
                      })
                    );
                  }}
                  id={route.path}
                >
                  {t(route.title)}
                </a>
              </Menu.Item>
            );
          }

          return (
            <Menu.Item key={route.path}>
              <Link href={route.path}>
                <a id={route.path}>{t(route.title)}</a>
              </Link>
            </Menu.Item>
          );
        }

        const subMenu = (
          <Menu>
            {route?.subs?.map((sub) => (
              <Menu.Item key={sub.path}>
                <Link href={sub.path}>
                  <a id={sub.path}>{t(sub.title)}</a>
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        );
        return (
          <Menu.Item key={route.title}>
            <Dropdown className={styles.earningDropdown} overlay={subMenu}>
              <a className="cursor">
                <span className="default">{t(route.title)}</span>
                <FontAwesomeIcon className={styles.downOutline} icon={faChevronDown} />
              </a>
            </Dropdown>
          </Menu.Item>
        );
      })}
      {user && (
        <Menu.Item key={routes.wallet}>
          <Link href={routes.wallet}>
            <a>{t('navbar.wallet.title')}</a>
          </Link>
        </Menu.Item>
      )}
      {/* <Menu.Item key="fx">
        <a href="https://fynxt.com/" target="_blank" rel="noopener noreferrer">
          FX (CFDs)
        </a>
      </Menu.Item> */}
    </>
  );
};

export const Desktop: FC = () => {
  const { theme, setTheme } = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [isOpenSetting, setIsOpenSetting] = useState<boolean>(false);
  const { exchange } = useAppSelector((state) => state.system);
  const { currentNotification, isOpenModal } = useAppSelector(getNotificationStore);
  const { user } = useUser();
  const { data: userInfo } = useGetUserInfo({ enabled: !!user });
  // const walletMenu = useMemo(() => getWalletMenu(t), [t]);

  const screens = useBreakpoint();
  const currentPage = router?.pathname.includes('/exchange') ? '/exchange/BTC_USDT' : router.pathname;

  const accountMenu = useMemo(() => getAccountMenu(t, user, userInfo?.kyc_status === 2), [t, user, userInfo]);
  const navList = useMemo(() => getNavList(t, user), [t, user]);

  const [isActive, toggleIsActive, setIsActive] = useToggle(theme === 'dark' ? true : false);

  useEffect(() => {
    setIsActive(theme === 'dark' ? true : false);
  }, [theme]);

  const handleToggle = () => {
    toggleIsActive();
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <Layout.Header className={styles.root}>
      <div className={clsx('container-fluid', styles.content)}>
        <Link href={routes.home}>
          <a>
            <img
              src={
                theme === 'light' && router.pathname.includes('/exchange')
                  ? '/images/logo_light.svg'
                  : '/images/logo.svg'
              }
              alt="logo"
              className={styles.logo}
            />
          </a>
        </Link>

        {screens.lg ? (
          <Menu selectedKeys={[currentPage]} activeKey={currentPage} mode="horizontal" className={styles.menu}>
            {navList}
          </Menu>
        ) : (
          <Menu activeKey={currentPage} mode="horizontal" className={styles.menu}>
            <SubMenu key="subMenu" icon={<FontAwesomeIcon icon={faAlignJustify} />}>
              {navList}
            </SubMenu>
          </Menu>
        )}

        {exchange.quickOrder && currentPage.includes('/exchange') && <QuickOrder />}
        <div className={styles.rightMenu}>
          <Space align="center" className={styles.buttons}>
            {user ? (
              <>
                <SubAccountDropdown />

                <Dropdown overlayClassName={styles.accountDropdown} overlay={accountMenu}>
                  <div className={styles.accountButton}>
                    <ButtonAntd
                      shape="circle"
                      type="text"
                      icon={
                        <Avatar src={user.picture} size={40} icon={<FontAwesomeIcon icon={faUser} color="#fff" />} />
                      }
                      size="middle"
                    />
                  </div>
                </Dropdown>
              </>
            ) : (
              <>
                <Button onClick={() => dispatch(setAuthModal({ type: 'login', screen: 'top_menu' }))}>
                  {t('navbar.nav.log_in')}
                </Button>
                <Button onClick={() => dispatch(setAuthModal({ type: 'signup', screen: 'top_menu' }))} type="primary">
                  {t('navbar.nav.register')}
                </Button>
              </>
            )}
            {currentPage.includes('/exchange') && (
              <ThemeSwitch
                value={isActive}
                onChange={handleToggle}
                switchProps={{
                  checkedChildren: <Candle />,
                  unCheckedChildren: <LightBulb />,
                  loading: false,
                }}
              />
            )}
            <LanguageSelect />
            <Hide if={!user}>
              <NotificationDropdown />
            </Hide>
            {currentNotification && (
              <NotificationModal visible={isOpenModal} notification={currentNotification}></NotificationModal>
            )}
            {currentPage.includes('/exchange') && (
              <div className={styles.settingButton}>
                <ButtonAntd
                  shape="circle"
                  icon={<SettingOutlined />}
                  size="middle"
                  onClick={() => setIsOpenSetting(true)}
                />
              </div>
            )}
            {isOpenSetting && <PopupTradeSetting visible={isOpenSetting} onCancel={() => setIsOpenSetting(false)} />}
          </Space>
        </div>
      </div>
    </Layout.Header>
  );
};
