import { FC, memo, useMemo } from 'react';
import styles from './NotificationDropdown.module.less';

import { Dropdown } from 'components/Dropdown';
import NotificationIcon from 'assets/svgs/components/Notification';
import { useGetAllNotification } from 'api/notification';
// import { getTokenDevice, turnOnMessage } from 'utils/firebase';
import Hide from 'components/Hide';
import MenuDropdown from './MenuDropdown';

export interface Notification {
  content: string;
  notifyKey: string;
  status: number;
  time: string;
  title: string;
  type?: number;
  refer?: string;
}

const NotificationDropDown: FC = () => {
  const { data: notifications, refetch } = useGetAllNotification();
  const notReadNotifications = useMemo(() => {
    return notifications?.sort((a, b) => parseInt(b.time) - parseInt(a.time)).filter((item) => item.status === 1);
  }, [notifications]);
  const showQuantity = useMemo(() => {
    if (!notReadNotifications?.length) {
      return 0;
    } else {
      if (notReadNotifications.length > 99) {
        return '99+';
      } else {
        return notReadNotifications.length;
      }
    }
  }, [notifications]);
  const menu = useMemo(() => {
    return <MenuDropdown></MenuDropdown>;
  }, [notifications]);
  return (
    <div>
      <Dropdown overlay={menu} className={styles.dropdown}>
        <div className={styles.wrapIcon}>
          <Hide if={!notReadNotifications?.length}>
            <div className={styles.quantity}>{showQuantity}</div>
          </Hide>
          <NotificationIcon />
        </div>
      </Dropdown>
    </div>
  );
};

export default memo(NotificationDropDown);
