import { useMarketGroupQuery } from 'api/market';
import { LoadingFullpage } from 'components/Loading';
import { useTypeSafeTranslation } from 'hooks';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { USER_COOKIES } from 'utils/constant';
import { removeCookies } from 'utils/cookies';
import styles from './styles.module.less';

const MaintenancePage: FC = () => {
  const { t } = useTypeSafeTranslation();
  const router = useRouter();
  const { isLoading } = useMarketGroupQuery({
    onSuccess: () => {
      removeCookies(USER_COOKIES.maintenance);
      router.push('/');
    },
  });

  if (isLoading) return <LoadingFullpage />;
  return (
    <>
      <div className={styles.bg}>
        <div className={styles.circle1} />
        <div className={styles.circle2} />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.title}>{t('common.maintenance')}</div>
        <div className={styles.desc}>{t('common.maintenance_desc')}</div>
        <img className={styles.logo} src="/images/maintenance/logo.png" alt="" />
      </div>
    </>
  );
};

export default MaintenancePage;
