/* eslint-disable @next/next/no-sync-scripts */
import { useState } from 'react';
import 'styles/globals.less';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import { AppProps } from 'next/app';
import store from 'store';
import { useRouteLoading } from 'hooks/useRouteLoading';
import { UserProvider } from '@auth0/nextjs-auth0';
import { ThemeProvider } from 'next-themes';

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Import the CSS
config.autoAddCss = false;

import { Layout } from 'components/Layout';
import { baseUrl, isProd } from 'utils/constant';

const queryClientOption = {
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: false, staleTime: 1000 * 5 },
  },
};
const coverUrl = `${baseUrl}/cover.png`;

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(() => new QueryClient(queryClientOption));
  useRouteLoading();

  return (
    <>
      <Head>
        <title>Ultorex - The ultimate exchange for the head runners</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta name="robots" content="all" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={baseUrl} />
        <meta property="og:title" content="Ultorex" />
        <meta property="og:description" content="The ultimate exchange for the head runners!" />
        <meta property="og:image" content={coverUrl} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={baseUrl} />
        <meta property="twitter:title" content="Ultorex" />
        <meta property="twitter:description" content="The ultimate exchange for the head runners!" />
        <meta property="twitter:image" content={coverUrl} />
        <meta
          name="keywords"
          content="Ultorex, exchange, trade, centralized exchanges, spot, margin, limit, bitcoin, eth"
        />
        <meta name="csrf-token" content={pageProps.csrfToken} />

        {typeof window === 'undefined' && (
          <style
            id="holderStyle"
            dangerouslySetInnerHTML={{
              __html: `
                *, *::before, *::after {
                  transition: none!important;
                }
                `,
            }}
          />
        )}
        <script
          dangerouslySetInnerHTML={{
            __html: isProd
              ? `(function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:3051528,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `
              : `
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:3152874,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `,
          }}
        />
      </Head>
      <Provider store={store}>
        <UserProvider user={pageProps?.user}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <ThemeProvider forcedTheme={(Component as any)?.theme || null} defaultTheme="dark">
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </ThemeProvider>
            </Hydrate>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </UserProvider>
      </Provider>
    </>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
// MyApp.getInitialProps = async (appContext: any) => {
//   const appProps = await App.getInitialProps(appContext);

//   const { ctx, router } = appContext;

//   try {
//     // exclude static site generation (Error pages)
//     if (!['/404', '/500', '/error'].includes(router.pathname)) {
//       const pageMaintain = await getMaintenanceStatus();
//       if (ctx?.res && pageMaintain?.includes(router.pathname)) {
//         ctx.res.writeHead(307, { Location: `${routes.maintain}?page=${router.pathname}` });
//         ctx.res.end();
//         return;
//       }
//     }
//   } catch (err) {
//     return { ...appProps };
//   }

//   return { ...appProps };
// };

export default appWithTranslation(MyApp);
