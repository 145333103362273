import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import styles from './NotificationDropdown.module.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { routes } from 'types/routes';
import NotificationIcon from 'assets/svgs/components/Notification';
import { AlreadyReadAll, updateDevice, useGetAllNotification } from 'api/notification';
import { useMutation } from 'react-query';
import ItemDropdown from './ItemDropdown';
import { Notification } from './NotificationDropdown';
import Link from 'next/link';
import { useLoadMore } from 'hooks/useLoadMore';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

interface Props {
  notReadNotifications: Notification[];
  refetch: Function;
}
const defaultSizeNotification = 10;
const MenuDropdown: FC = () => {
  const { data: notifications, refetch } = useGetAllNotification({
    select: (list) => {
      return list?.sort((a, b) => parseInt(b.time) - parseInt(a.time)).filter((item) => item.status === 1);
    },
  });

  const [currentList, loadMore] = useLoadMore(notifications || [], defaultSizeNotification);
  const { mutateAsync: mutateReadAll } = useMutation(AlreadyReadAll, {
    onSuccess: () => {
      refetch();
    },
  });
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;
  useEffect(() => {
    if (isVisible) {
      loadMore();
    }
  }, [isVisible]);

  const handleClear = () => {
    mutateReadAll();
  };
  return (
    <div className={styles.wrapMenuNoti}>
      <div className={styles.menuNoti}>
        <div className={styles.header}>
          <div className={styles.left}>
            {<div className={styles.quantity}>{notifications?.length}</div>}
            <div>pending notifications</div>
            <a onClick={handleClear} className={styles.clear}>
              Clear All
            </a>
          </div>
          <Link href={`${routes.notification}`} className={styles.view} passHref>
            <a>
              <span>View All</span>
              <FontAwesomeIcon className="primary" icon={faArrowRight} />
            </a>
          </Link>
        </div>
        {currentList &&
          currentList.map((notification: Notification) => {
            return <ItemDropdown key={notification.notifyKey} notification={notification}></ItemDropdown>;
          })}
        <div ref={ref}>&nbsp;</div>
        {!currentList.length && (
          <div className={styles.nonNotification}>
            <NotificationIcon /> <span> No new notification</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(MenuDropdown);
