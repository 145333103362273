import { Avatar } from 'components/Avatar';
import { useState, useEffect } from 'react';
import { currencyImgs } from 'assets/images/currency';
import styles from './QuickOrder.module.css';
import { Button } from 'components/Button';
import { InputNumber } from 'antd';
import { Toast } from 'components/Toast';
import { useAppSelector, useAppDispatch, useTypeSafeTranslation } from 'hooks';
import { placeOrderMarket } from 'api/market';
import { useMutation } from 'react-query';
import { TError } from 'api/types';
import { useWalletQuery } from 'api/wallet';
import { usePairListQuery } from 'api/pair_list';
import { fixed, nDecimalFormat } from 'utils/number';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { setExchange } from 'store/ducks/system/slice';
import Draggable from 'react-draggable';

export const QuickOrder = () => {
  const { t } = useTypeSafeTranslation();
  const dispatch = useAppDispatch();
  const { currentPair } = useAppSelector((state) => state.system.exchange);
  const { listPairValue, currentPairValue } = useAppSelector((state) => state.exchange);
  const { data, refetch }: any = useWalletQuery();
  const { data: pairs } = usePairListQuery();

  const [headerData, setHeaderData] = useState<any>(null);
  const [amount, setAmount] = useState<any>(0);

  const coin = currentPair?.split('_')[0];
  const moneyCoin = currentPair?.split('_')[1];

  const moneyCoinAvailable = data?.coins?.find((coinData: any) => coinData.coinType === moneyCoin)?.number || 0;
  const coinAvailable = data?.coins?.find((coinData: any) => coinData.coinType === coin)?.number || 0;

  const coinLimit = data?.coinsLimitMap[coin];

  const currentPairData = pairs?.find((pair: any) => pair[0] === currentPair);

  const coinDecimalAmount = Number((currentPairData && currentPairData[2]) || 0);

  useEffect(() => {
    let headerData = null;
    if (listPairValue.length > 0) {
      headerData = listPairValue.find((listPairValue_item: any) => listPairValue_item.pair === currentPair);
    }
    setHeaderData(headerData);
  }, [listPairValue, currentPair]);

  const { mutateAsync: mutatePlaceOrderMarket, isLoading } = useMutation(placeOrderMarket, {
    onSuccess: () => {
      Toast('success', t('exchange.place_order.order_success'));
      refetch();
    },
    onError: (error: TError) => {
      Toast('error', error.message);
    },
  });

  const handleOrder = (isBuy: boolean) => {
    const orderData = {
      coin1: coin,
      coin2: moneyCoin,
      type: 'LIMIT',
      is_bid: isBuy,
      param1: isBuy ? headerData?.buy : headerData?.sell,
      param2: Number(fixed('' + amount, Number(coinDecimalAmount))),
      param3: 0,
      pool: 1,
    };
    switch (true) {
      case !amount:
        Toast('error', t('exchange.place_order.empty_amount'));
        break;
      case amount < coinLimit:
        Toast('error', t('exchange.place_order.minimum_amount') + coinLimit);
        break;
      case isBuy && amount * headerData?.buy > moneyCoinAvailable:
        Toast('error', t('exchange.place_order.not_enough_balance'));
        break;
      case !isBuy && amount > coinAvailable:
        Toast('error', t('exchange.place_order.not_enough_balance'));
        break;
      default:
        mutatePlaceOrderMarket(orderData);
    }
  };

  const onClose = () => {
    dispatch(setExchange({ name: 'quickOrder', value: false }));
  };

  return (
    <Draggable>
      <div className={styles.root}>
        <FontAwesomeIcon color="#788686" icon={faTimesCircle} className={styles.btnClose} onClick={onClose} />
        {/* <FontAwesomeIcon color="#788686" icon={faDotCircle} className={styles.btnDot} /> */}
        <div className={styles.info}>
          <div className={styles.buyPrice}>{nDecimalFormat(headerData?.buy, currentPairValue?.[3] ?? 2)}</div>
          <div className="currency">
            <Avatar size={24} type="secondary" src={currencyImgs && currencyImgs[coin]} />
            <span className="bold default">{coin}</span>
          </div>
          <div className={styles.sellPrice}>{nDecimalFormat(headerData?.sell, currentPairValue?.[3] ?? 2)}</div>
        </div>
        <div className={styles.inputOrder}>
          <div className={styles.inputWrapper}>
            <Button loading={isLoading} type="buy" className={styles.buyButton} onClick={() => handleOrder(true)}>
              {t('exchange.place_order.buy')}
            </Button>
            <InputNumber
              value={coinDecimalAmount && amount ? Number(fixed('' + amount, Number(coinDecimalAmount))) : ''}
              placeholder={`${t('exchange.amount')} ${coin}`}
              onChange={(value) => setAmount(value)}
              className={styles.inputAmount}
            />
            <Button loading={isLoading} type="sell" className={styles.sellButton} onClick={() => handleOrder(false)}>
              {t('exchange.place_order.sell')}
            </Button>
          </div>
        </div>
      </div>
    </Draggable>
  );
};
