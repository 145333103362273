export enum routes {
  home = '/',
  register = '/register',
  login = '/login',
  dashboard = '/dashboard',
  changePassword = '/change-password',
  identifyVerification = '/kyc',
  twoFactorAuth = '/two-factor-auth',
  market = '/market',
  accountManagement = '/account',
  history = '/history',
  wallet = '/wallet',
  exchange = '/exchange',
  spotWallet = '/spot-wallet',
  buy = '/buy',
  trade = '/trade',
  stake = '/stake',
  rewardCenter = '/reward-center',
  referral = '/referral',
  earnings = '/earning',
  deposit = '/wallet/deposit',
  withdraw = '/wallet/withdraw',
  withdrawAddressManagement = '/wallet/withdraw/addresses',
  subAccount = '/sub-account',
  api = '/api-key',
  privacyPolicy = '/privacy-policy',
  aboutUs = '/about-us',
  termsOfUse = '/terms-of-use',
  ultEarn = '/ult-earn',
  maintenance = '/maintenance',
  comingSoon = '/coming-soon',
  maintain = '/maintain',
  symbol = '/symbol',
  upcoming = '/upcoming',
  campaign = '/campaign',
  swap = '/swap',
  orbsTop10 = '/orbs-top-10',
  notification = '/notification',
  solCampaign = '/sol-campaign',
}

export enum ReferralTab {
  dashboard = 'dashboard',
  earnings = 'earnings',
  referrals = 'referrals',
  banners = 'banners',
}
