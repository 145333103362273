import { FC } from 'react';
import { Col, Modal, Row, Switch } from 'antd';
import styles from './PopupTradeSetting.module.css';
import SettingOutlined from '@ant-design/icons/lib/icons/SettingOutlined';
import { useAppDispatch, useAppSelector, useTypeSafeTranslation } from 'hooks';
import { setExchange } from 'store/ducks/system/slice';
// import { useTheme } from 'next-themes';

interface Modal2FAProps {
  visible: boolean;
  onCancel: () => void;
}

interface SwitcherProps {
  title: string;
  name: string;
  value: boolean;
  handleChangeSetting: (name: string, value: boolean) => void;
}

const Switcher = ({ title, name, value, handleChangeSetting }: SwitcherProps) => {
  return (
    <div className={styles.switcher}>
      <div className={styles.title}>{title}</div>
      <div className="switchBtn">
        <Switch checked={value} onChange={(value) => handleChangeSetting(name, value)} />
      </div>
    </div>
  );
};

export const PopupTradeSetting: FC<Modal2FAProps> = ({ visible, onCancel }) => {
  const { t } = useTypeSafeTranslation();
  // const { theme, setTheme } = useTheme();
  const { exchange } = useAppSelector((state) => state.system);
  const dispatch = useAppDispatch();
  const handleChangeSetting = (name: any, value: boolean) => {
    dispatch(setExchange({ name, value }));
  };
  return (
    <Modal visible={visible} className={styles.root} onCancel={onCancel} centered>
      <div className={styles.header}>
        <SettingOutlined className={styles.settingIcon} />
        <div className={styles.popupTitle}>{t('exchange.trade_preferences')}</div>
      </div>
      <div className={styles.inner}>
        {/* <Row gutter={8}>
          <Col span={12} className={styles.selectWrapper}>
            <SelectWithLabel
              className={styles.normal}
              value={exchange.language}
              label={t('common.lang')}
              onChange={(value) => handleChangeSetting('language', value)}
            >
              {LANGUAGE.map((option, index) => (
                <Option className="uppercase" key={index} value={option.value}>
                  {option.value}
                </Option>
              ))}
            </SelectWithLabel>
          </Col>
          <Col span={12}>
            <SelectWithLabel
              className={styles.normal}
              value={exchange.currency}
              label={t('exchange.def_currency')}
              onChange={(value) => handleChangeSetting('currency', value)}
            >
              {['BTC', 'ETH', 'USDT'].map((option, index) => (
                <Option key={index} value={option}>
                  {option}
                </Option>
              ))}
            </SelectWithLabel>
          </Col>
        </Row> */}
        <div className={styles.boxTitle}>{t('exchange.trade_screen_boxes')}</div>
        <Row gutter={8}>
          <Col span={12}>
            <Switcher
              title={t('exchange.quick_order')}
              value={exchange.quickOrder}
              handleChangeSetting={handleChangeSetting}
              name="quickOrder"
            />
          </Col>
          <Col span={12}>
            <Switcher
              title={t('exchange.market')}
              value={exchange.market}
              name="market"
              handleChangeSetting={handleChangeSetting}
            />
          </Col>
          <Col span={12}>
            <Switcher
              title={t('exchange.chart')}
              value={exchange.chart}
              name="chart"
              handleChangeSetting={handleChangeSetting}
            />
          </Col>
          <Col span={12}>
            <Switcher
              title={t('exchange.order_book')}
              value={exchange.orderBook}
              name="orderBook"
              handleChangeSetting={handleChangeSetting}
            />
          </Col>
          <Col span={12}>
            <Switcher
              title={t('exchange.trades')}
              value={exchange.trades}
              name="trades"
              handleChangeSetting={handleChangeSetting}
            />
          </Col>
          <Col span={12}>
            <Switcher
              title={t('historypage.table.openOrders')}
              value={exchange.openOrders}
              name="openOrders"
              handleChangeSetting={handleChangeSetting}
            />
          </Col>
          <Col span={12}>
            <Switcher
              title={t('exchange.wallet_snap')}
              name="walletSnap"
              value={exchange.walletSnap}
              handleChangeSetting={handleChangeSetting}
            />
          </Col>
          {/* <Col span={12}>
            <Switcher
              title={`Toggle theme`}
              name="theme"
              value={theme === 'light'}
              handleChangeSetting={(_, value) => setTheme(value ? 'light' : 'dark')}
            />
          </Col> */}
        </Row>
      </div>
    </Modal>
  );
};
