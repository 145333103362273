import { FC } from 'react';
import styles from './NotificationModal.module.less';
import { Modal } from 'components/Modal';

import { Button } from 'components/Button';
import { Divider } from 'antd';
import { useTypeSafeTranslation } from 'hooks/useTypeSafeTranslation';
import { Notification } from './NotificationDropdown';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useAppDispatch } from 'hooks';
import { setIsOpen } from 'store/ducks/notification/slice';

interface Props {
  visible?: boolean;
  onClose?: () => void;
  notification: Notification;
}

const NotificationModal: FC<Props> = ({ notification, visible }) => {
  const { t } = useTypeSafeTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const handleViewMore = () => {
    if (notification.refer) {
      router.replace(notification.refer);
    }
  };
  const handleClose = () => {
    dispatch(setIsOpen(false));
  };
  return (
    <Modal onCancel={handleClose} closeIcon={false} centered width={400} visible={visible}>
      <div className={styles.header}>Notification Detail</div>
      <Divider className={styles.divider}></Divider>

      <div className={styles.body}>
        <div className={styles.title}>{notification.title}</div>
        <div className={styles.time}>{dayjs(parseInt(notification.time)).format('HH:mm:ss - DD/MM')}</div>
        <div className={styles.content}>{notification.content}</div>
      </div>
      <div className={styles.wrapButton}>
        <Button onClick={handleClose} className={styles} type="success">
          {t('common.close')}
        </Button>
        {/* <Button onClick={handleViewMore} type="primary">
          {t('common.view_more')}
        </Button> */}
      </div>
    </Modal>
  );
};

export default NotificationModal;
