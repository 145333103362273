import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';
import { Desktop } from './Desktop';
import Mobile from './Mobile';

const Navbar = () => {
  const screen = useBreakpoint();

  return <>{screen.md ? <Desktop /> : <Mobile />}</>;
};

export default Navbar;
