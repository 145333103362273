import { useEffect } from 'react';
import { WEB_SOCKET_URL } from 'utils/constant';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useUser } from '@auth0/nextjs-auth0';
import { getAuthToken } from 'api/auth_token';
import { parseJson } from 'utils/cookies';
import { setWallet } from 'store/ducks/wallet/slice';

interface IAuthSocketConfig {
  subscribeWallet?: boolean;
  subscribeOpenOrder?: boolean;
  whenAuthSocketChange?: () => void;
}

export const useAuthWebSocket = (config: IAuthSocketConfig) => {
  const { user } = useUser();
  const dispatch = useAppDispatch();
  const currentSubAccount = useAppSelector((state) => state.subAccount.currentSubAccount);

  const { readyState, sendMessage, lastMessage } = useWebSocket(WEB_SOCKET_URL, {
    shouldReconnect: () => true,
    reconnectAttempts: 20,
    reconnectInterval: 3000,
  });

  useEffect(() => {
    if (readyState === ReadyState.OPEN && !!user) {
      (async () => {
        const authTokenResponse = await getAuthToken();
        if (authTokenResponse['auth-token']) {
          const authTokenMsg = `{"event":"loginToken", "token":"${authTokenResponse['auth-token']}"}`;
          sendMessage(authTokenMsg);
          if (config.whenAuthSocketChange) config.whenAuthSocketChange();
        }
      })();
    }
  }, [readyState, user, currentSubAccount]);

  useEffect(() => {
    if (!lastMessage || (lastMessage?.data === "{'ping':''}" && !config.subscribeWallet)) return;
    const message = parseJson(lastMessage.data);

    if (message && message.channel) {
      switch (message.channel) {
        case 'auth-wallet':
          dispatch(setWallet(message.data));
          break;
        default:
          break;
      }
    }

    if (message?.event === 'loginToken') {
      const authWalletMsg = `{"event":"subscribe", "channel":"auth-wallet"}`;
      sendMessage(authWalletMsg);
    }
  }, [readyState, lastMessage, config.subscribeWallet, config.subscribeOpenOrder]);

  return {
    user,
    currentSubAccount,
    readyState,
    sendMessage,
    lastMessage,
    isSocketReady: readyState === ReadyState.OPEN,
  };
};
