import { useQuery, UseQueryOptions } from 'react-query';
import { getCampaignStatus, getMaintenanceStatus, getTop10Orbs, getTopCampaign } from './request';
import { IORBSTop, ITopCampaign } from './types';

export const usePageMaintain = (options?: UseQueryOptions<string[]>) => {
  return useQuery<string[]>('/system/maintenance/page', getMaintenanceStatus, options);
};

export const useCampaignStatus = (options?: UseQueryOptions<any>) => {
  return useQuery<any>('/mine/orbs-deposit-staking', getCampaignStatus, options);
};

export const useTopCampaign = (options?: UseQueryOptions<ITopCampaign[]>) => {
  return useQuery<ITopCampaign[]>('/user/reward/top?size=10', getTopCampaign, options);
};

export const useTop10ORBS = (options?: UseQueryOptions<IORBSTop[]>) => {
  return useQuery<IORBSTop[]>('mine/getListTop10ORBS', getTop10Orbs, options);
};
