import { AlreadyRead, useGetAllNotification } from 'api/notification';
import dayjs from 'dayjs';
import { useAppDispatch, useToggle } from 'hooks';
import { FC } from 'react';
import { useMutation } from 'react-query';
import { setCurrentNotification, setIsOpen } from 'store/ducks/notification/slice';
import { Notification } from './NotificationDropdown';
import styles from './NotificationDropdown.module.less';

export interface Props {
  notification: Notification;
  key: string;
}
const ItemDropdown: FC<Props> = ({ notification }) => {
  const [openModal, toggleModal] = useToggle(false);
  const { refetch } = useGetAllNotification();
  const dispatch = useAppDispatch();
  const { mutate: mutateAlreadyRead } = useMutation(AlreadyRead, {
    onSuccess: async () => {
      refetch();
    },
  });
  const handleClickedItem = () => {
    dispatch(setCurrentNotification(notification));
    dispatch(setIsOpen(true));
    mutateAlreadyRead({ notifyId: notification.notifyKey });
    toggleModal();
  };
  return (
    <div key={notification.notifyKey} onClick={handleClickedItem} className={styles.item}>
      <div className={styles.title}>{notification.title}</div>
      <div className={styles.description}>{notification.content} </div>
      <div className={styles.time}>{dayjs(parseInt(notification.time)).format('HH:mm:ss - DD/MM')}</div>
    </div>
  );
};

export default ItemDropdown;
