import { FC, useEffect, useMemo } from 'react';
import styles from './Layout.module.less';
import dynamic from 'next/dynamic';

import { Layout as AntdLayout } from 'antd';
import { Footer } from './Footer';
import NavBar from './Navbar/index';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import { TFunction, useTranslation } from 'next-i18next';
import { useAppSelector } from 'hooks';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import AuthModal from 'components/AuthModal/AuthModal';
import { getCookies } from 'utils/cookies';
import { USER_COOKIES } from 'utils/constant';
import MaintenancePage from 'modules/MaintenancePage';
import { routes } from 'types/routes';

import trTR from 'antd/lib/locale/tr_TR';
import zhCN from 'antd/lib/locale/zh_CN';
import zhHK from 'antd/lib/locale/zh_HK';
import koKR from 'antd/lib/locale/ko_KR';
import { useTheme } from 'next-themes';
import { usePageMaintain } from 'api/auth_token/queries';
import Hide from 'components/Hide';
import { debounce } from 'lodash';

const Moduler = dynamic(() => import('./Moduler'), { ssr: false });

const getValidateMessages = (t: TFunction) => ({
  required: `* \${label} ${t('form.validate.required')}`,
  whitespace: '${name} cannot be empty',
  types: {
    number: t('e_ERROR_TYPE_NUMBER', { ns: 'error' }),
    date: t('e_ERROR_TYPE_DATE', { ns: 'error' }),
    integer: t('e_ERROR_TYPE_INTEGER', { ns: 'error' }),
    float: t('e_ERROR_TYPE_FLOAT', { ns: 'error' }),
    email: t('e_ERROR_TYPE_EMAIL', { ns: 'error' }),
    url: t('e_ERROR_TYPE_URL', { ns: 'error' }),
  },
});

const hideBackground = ['/upcoming', '/campaign', '/chart/[id]', '/orbs-top-10', '/sol-campaign'];
const BlankLayoutPages = ['/404', '/500', '/error', '/maintenance', '/chart/[id]'];

export const Layout: FC = ({ children }) => {
  const { t } = useTranslation();
  const { theme, setTheme } = useTheme();
  const router = useRouter();

  const fullscreen = useAppSelector((state) => state.system.exchange.fullscreen);

  const validateMessages = useMemo(() => getValidateMessages(t), [t]);

  const { refetch } = usePageMaintain({
    onSuccess: (data) => {
      if (data?.includes(router.pathname)) {
        return router.push(`${routes.maintain}?page=${router.pathname}`);
      }
    },
    staleTime: 20000,
    refetchInterval: 20000,
  });

  const blankLayout = useMemo((): boolean => {
    return BlankLayoutPages.includes(router.pathname);
  }, [router]);

  useEffect(() => {
    if (getCookies(USER_COOKIES.maintenance) === 'true' && router.pathname !== routes.maintenance) {
      router.push(routes.maintenance);
    }

    if (!router.pathname.includes(routes.exchange) && !router.pathname.includes('/chart')) {
      setTheme('dark');
    }
  }, [router]);

  const refetchWhenPathChange = debounce(() => {
    refetch();
  }, 20000);

  useEffect(() => {
    refetchWhenPathChange();
  }, [router.pathname]);

  const inMaintenance = getCookies(USER_COOKIES.maintenance) === 'true';

  const locales = useMemo(() => {
    switch (router.locale) {
      case 'tr':
        return trTR;
      case 'zh-CN':
        return zhCN;
      case 'zh-TW':
        return zhHK;
      case 'ko':
        return koKR;
      default:
        return undefined;
    }
  }, [router.locale]);

  if (inMaintenance) return <MaintenancePage />;

  return (
    <AntdConfigProvider
      form={{
        validateMessages,
      }}
      locale={locales}
    >
      <Moduler />
      <AntdLayout className={clsx(styles.root, fullscreen ? styles.minHeight : undefined)}>
        {!blankLayout && <NavBar />}
        <Hide
          if={
            (theme === 'light' && router.pathname.includes('/exchange')) ||
            router.pathname === '/' ||
            hideBackground.includes(router.pathname)
          }
        >
          <div className={styles.bg}>
            <div className={styles.circle1} />
            <div className={styles.circle2} />
          </div>
        </Hide>
        <main className={fullscreen ? styles.fullscreen : undefined}>{children}</main>

        <AuthModal />
        {!blankLayout && <Footer />}
      </AntdLayout>
    </AntdConfigProvider>
  );
};
