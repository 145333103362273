import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Notification } from 'modules/NotificationPage/components/NotificationDropdown';
import { ActionBanners } from 'modules/HomePage/components/ActionBanners';

export interface TNotificationReducer {
  currentNotification: Notification | null;
  isOpenModal: boolean;
}

const initialState = {
  currentNotification: null,
  isOpenModal: false,
} as TNotificationReducer;

const notificationSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setCurrentNotification(state, action: PayloadAction<Notification>) {
      return {
        ...state,
        currentNotification: action.payload,
      };
    },
    setIsOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isOpenModal: action.payload,
      };
    },
  },
});

export const getCurrentNotification = (state: RootState): any => state.notification.currentNotification;

export const isOpenModal = (state: RootState): any => state.notification.isOpenModal;

export const getNotificationStore = (state: RootState): any => state.notification;
export const { setCurrentNotification, setIsOpen } = notificationSlice.actions;

export default notificationSlice.reducer;
