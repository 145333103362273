import { IAlreadyRead, IDeviceRequest } from './types';
import { authRequest, request } from 'api/axios';

export const getAllNotification = async (): Promise<any[]> => {
  const { data } = await authRequest.post('/notify/app/get-all');
  return data;
};

export const updateDevice = async (param: IDeviceRequest): Promise<any> => {
  const { data } = await authRequest.post('/notify/app/update-device-info', param);
  return data;
};
export const AlreadyRead = async (param: IAlreadyRead): Promise<any> => {
  const { data } = await authRequest.post('/notify/app/already-read', param);
  return data;
};
export const AlreadyReadAll = async (): Promise<any> => {
  const { data } = await authRequest.post('/notify/app/already-read-all');
  return data;
};
