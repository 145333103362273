import { FC, useMemo } from 'react';
import styles from './styles.module.less';

import { Modal } from 'components/Modal';
import { useAppDispatch, useAppSelector, useTypeSafeTranslation } from 'hooks';
import { getAuthModal, setAuthModal } from 'store/ducks/system/slice';
import { Surface } from 'components/Surface';
import { Button } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { isProd, SKIP_ID_URI } from 'utils/constant';
import { useRouter } from 'next/router';

const AuthModal: FC = () => {
  const { t } = useTypeSafeTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const initEmail = router.query.email;

  const state = useAppSelector(getAuthModal);

  const handleClose = () => {
    dispatch(setAuthModal(undefined));
  };

  const get = useMemo(() => {
    switch (state?.type) {
      case 'login':
        return {
          title: t('login.title'),
          button: t('login.button.login'),
          url: '/api/auth/login',
        };
      case '2fa':
        return {
          title: t('login.setup_2fa'),
          button: t('login.setup_2fa'),
          url: 'two-factor-auth',
        };
      case 'signup':
        return {
          title: t('login.button.register'),
          button: t('login.button.register'),
          url: initEmail ? `/api/auth/signup?email=${initEmail}` : `/api/auth/signup`,
        };
      default:
        return {
          title: t('login.registration_required'),
          button: t('login.button.register'),
          url: initEmail ? `/api/auth/signup?email=${initEmail}` : `/api/auth/signup`,
        };
    }
  }, [state, initEmail]);

  const handleNavigate = () => {
    // ---------------------------------- HotJAR

    if (state?.type !== 'login' && state?.type !== '2fa') {
      (window as any).hj('event', 'register_popups');
    }

    if (state?.type === 'signup' || state?.type === 'auth') {
      switch (state.screen) {
        case 'banner': {
          (window as any).hj('event', 'register_from_banner');
          break;
        }
        case 'top_menu': {
          (window as any).hj('event', 'register_from_top_menu');
          break;
        }
        case '3_step': {
          (window as any).hj('event', 'register_from_3_step');
          break;
        }
        case 'landing_bottom': {
          (window as any).hj('event', 'register_from_landing_bottom');
          break;
        }
        case 'stake': {
          (window as any).hj('event', 'register_from_stake_page');
          break;
        }
        case 'earn': {
          (window as any).hj('event', 'register_from_earn_page');
          break;
        }
      }
    }

    if (state?.type === 'login' && state.screen === 'top_menu') {
      (window as any).hj('event', 'login_from_top_menu');
    }

    // ----------------------------------

    if (state?.type === '2fa') {
      window.open(`${SKIP_ID_URI}/mfa/enable`);
    } else {
      window.location.href = get.url;
    }
  };

  return (
    <Modal width={325} centered visible={!!state} onCancel={handleClose}>
      <div className={styles.header}>
        <p className="primary bold uppercase">{get.title}</p>
        <span className="text-12">{t('login.description')}</span>
      </div>

      <Surface className={styles.card} forceDark filled borderLess>
        <a href={SKIP_ID_URI} target="_blank" rel="noopener noreferrer">
          <img src="/images/svgs/skipId-logo.svg" alt="skip-id" />
        </a>
      </Surface>

      <Button onClick={handleNavigate} className={styles.btnSubmit} type="primary">
        {get.button}
      </Button>

      {state?.type === 'auth' && (
        <div className={styles.outerModal}>
          <p className="text-12 m-0">{t('login.already_have_an_account')}</p>
          <a href={`/api/auth/login?returnTo=${router.pathname}`}>
            <Button type="text">
              <span className="primary">{t('login.title')}</span>
              <FontAwesomeIcon color="#fff" icon={faArrowRight} />
            </Button>
          </a>
        </div>
      )}
    </Modal>
  );
};

export default AuthModal;
