import React, { FC, useMemo, useState } from 'react';
import styles from './Mobile.module.less';

import Link from 'next/link';
import { routes } from 'types/routes';
import { Button, Drawer, Space, Menu, Divider } from 'antd';
import { useUser } from '@auth0/nextjs-auth0';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faGlobe, faPowerOff, faUser, faWallet } from '@fortawesome/free-solid-svg-icons';
import { SettingOutlined } from '@ant-design/icons';
import { navigationRoutes, accountRoutes } from './router';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { Button as CustomButton } from 'components/Button';
import { Avatar } from 'components/Avatar';

import { LanguageSelect } from 'components/LanguageSelect';
import { PopupTradeSetting } from '../PopupTradeSetting';
import { logout } from 'utils/auth';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector, useTypeSafeTranslation } from 'hooks';
import { setAuthModal } from 'store/ducks/system/slice';
import { useSubAccountsQuery } from 'api/sub_account';
import { getCurrentSubAccount, MAIN_ACCOUNT_KEY } from 'store/ducks/sub_account/slice';
import { useGetUserInfo } from 'api/account';
import NotificationIcon from 'assets/svgs/components/Notification';
import Hide from 'components/Hide';
import { useGetAllNotification } from 'api/notification';

interface IDrawerMenu {
  visible: boolean;
  onClose: () => void;
}

const { SubMenu } = Menu;

const NavigatorDrawer: FC<IDrawerMenu> = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const [openSetting, setOpenSetting] = useState(false);
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const { user } = useUser();

  return (
    <Drawer className={styles.drawer} width={360} onClose={onClose} visible={visible}>
      {!user && (
        <Space direction="vertical" className={styles.authWrap}>
          <CustomButton
            onClick={() => {
              dispatch(setAuthModal({ type: 'login', screen: 'top_menu' }));
              onClose();
            }}
            block
          >
            {t('navbar.nav.log_in')}
          </CustomButton>

          <CustomButton
            onClick={() => {
              dispatch(setAuthModal({ type: 'signup', screen: 'top_menu' }));
              onClose();
            }}
            block
            type="primary"
          >
            {t('navbar.nav.register')}
          </CustomButton>
        </Space>
      )}

      <Menu activeKey={pathname === '/' ? undefined : pathname} mode="inline" className={styles.menu} onClick={onClose}>
        {navigationRoutes.map((route) => {
          if (route.path) {
            if (route.path === routes.buy && !user) {
              return (
                <Menu.Item key={route.path}>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(setAuthModal({ type: 'auth' }));
                    }}
                    id={route.path}
                  >
                    {t(route.title)}
                  </a>
                </Menu.Item>
              );
            }
            return (
              <Menu.Item icon={<FontAwesomeIcon icon={route.icon} />} key={route.path}>
                <Link key={route.path} href={route.path}>
                  {t(route.title)}
                </Link>
              </Menu.Item>
            );
          }

          return (
            <SubMenu key={route.title} icon={<FontAwesomeIcon icon={route.icon} />} title={t(route.title)}>
              {route?.subs?.map((sub) => (
                <Menu.Item key={sub.path}>
                  <Link key={sub.path} passHref href={sub.path}>
                    <div className={styles.uppercase}>{t(sub.title)}</div>
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          );
        })}

        {user && (
          <Menu.Item icon={<FontAwesomeIcon icon={faWallet} />} key={routes.wallet}>
            <Link href={routes.wallet}>
              <a>{t('navbar.wallet.title')}</a>
            </Link>
          </Menu.Item>
        )}
        {user && (
          <Menu.Item icon={<FontAwesomeIcon icon={faBell} />} key={routes.notification}>
            <Link href={routes.notification}>
              <a>{t('navbar.menu.notification')}</a>
            </Link>
          </Menu.Item>
        )}
        {/* <Menu.Item key="fx">
          <a href="https://fynxt.com/" target="_blank" rel="noopener noreferrer">
            FX (CFDs)
          </a>
        </Menu.Item> */}
      </Menu>
      <Divider />
      <div className={styles.menuStandalone}>
        <FontAwesomeIcon icon={faGlobe} /> <LanguageSelect desktop={false} />
      </div>

      {pathname === '/exchange' && (
        <div
          onClick={() => {
            setOpenSetting(true);
            onClose();
          }}
          className={styles.menuStandalone}
        >
          <Space align="start" size={16}>
            <SettingOutlined /> Trade setting
          </Space>
        </div>
      )}
      {openSetting && <PopupTradeSetting visible={openSetting} onCancel={() => setOpenSetting(false)} />}
    </Drawer>
  );
};

const UserDrawer: FC<IDrawerMenu> = ({ visible, onClose }) => {
  const { t } = useTypeSafeTranslation();
  const { pathname } = useRouter();
  const { user } = useUser();

  const { data: subAccounts } = useSubAccountsQuery({ enabled: !!user });
  const currentSub = useAppSelector(getCurrentSubAccount);
  const { data: userInfo } = useGetUserInfo({ enabled: !!user });

  const nickname = useMemo(() => {
    if (currentSub === MAIN_ACCOUNT_KEY || !subAccounts) {
      return undefined;
    }
    let acc = subAccounts.find((element) => element.accountId === currentSub);
    if (acc) {
      return acc.nickName;
    }
    return undefined;
  }, [currentSub, subAccounts]);

  return (
    <Drawer className={styles.drawer} width={360} onClose={onClose} visible={visible}>
      <Link href={routes.dashboard}>
        <a onClick={onClose} className={clsx(styles.menuStandalone, styles.email)}>
          <Space>
            {userInfo?.kyc_status === 2 && <img src="/images/svgs/badge-check-duotone.svg" alt="icon" />}
            {user?.email}
          </Space>
          {nickname && (
            <div className={styles.nickNameText}>
              <small>
                {t('sub_account.sub')} {nickname}
              </small>
            </div>
          )}
        </a>
      </Link>

      <Menu activeKey={pathname === '/' ? undefined : pathname} mode="inline" className={styles.menu} onClick={onClose}>
        {accountRoutes.map((route) => (
          <Menu.Item icon={<img width="24" src={route.icon} alt="icon" />} key={route.path}>
            {route.disabled ? (
              <div className={styles.uppercase}>{t(route.title as any)}</div>
            ) : (
              <Link key={route.path} passHref href={route.path}>
                <div className={styles.uppercase}>{t(route.title as any)}</div>
              </Link>
            )}
          </Menu.Item>
        ))}

        <Menu.Item icon={<FontAwesomeIcon icon={faWallet} />} key={routes.wallet}>
          <Link key={routes.wallet} passHref href={routes.wallet}>
            <div className={styles.uppercase}>{t('navbar.wallet.title')}</div>
          </Link>
        </Menu.Item>
        {/* <SubMenu key="wallet-parent" icon={<FontAwesomeIcon icon={faWallet} />} title={t('navbar.wallet.title')}>
          {walletRoutes.map((route) => (
            <Menu.Item key={route.path}>
              <Link key={route.path} passHref href={route.path}>
                <div className={styles.uppercase}>{t(route.title)}</div>
              </Link>
            </Menu.Item>
          ))}
        </SubMenu> */}
      </Menu>
      <Divider key="divider" />

      <Menu mode="inline" className={styles.menu}>
        <Menu.Item key="logout" onClick={logout} icon={<FontAwesomeIcon icon={faPowerOff} />}>
          {t('navbar.account.log_out')}
        </Menu.Item>
      </Menu>
    </Drawer>
  );
};

const Mobile = () => {
  const { user } = useUser();
  const [openNavigator, setOpenNavigator] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const router = useRouter();
  const toggleOpenUser = () => {
    setOpenUser(!openUser);
  };
  const toggleOpenNavigator = () => {
    setOpenNavigator(!openNavigator);
  };
  const { data: notifications, refetch } = useGetAllNotification();

  const notReadNotifications = useMemo(() => {
    return notifications?.sort((a, b) => parseInt(b.time) - parseInt(a.time)).filter((item) => item.status === 1);
  }, [notifications]);
  const showQuantity = useMemo(() => {
    if (!notReadNotifications?.length) {
      return 0;
    } else {
      if (notReadNotifications.length > 99) {
        return '99+';
      } else {
        return notReadNotifications.length;
      }
    }
  }, [notifications]);
  return (
    <>
      <div className={styles.root}>
        <Link href={routes.home}>
          <a>
            <img src="/images/logo.svg" alt="logo" className={styles.logo} />
          </a>
        </Link>

        <Space align="end">
          {user && (
            <div
              onClick={() => {
                router.push(routes.notification);
              }}
              className={clsx(styles.wrapIcon, !notReadNotifications?.length ? styles.nonNotification : '')}
            >
              <Hide if={!notReadNotifications?.length}>
                <div className={styles.quantity}>{showQuantity}</div>
              </Hide>
              <NotificationIcon />
            </div>
          )}

          {user && (
            <Button
              onClick={toggleOpenUser}
              shape="circle"
              type="text"
              icon={<Avatar src={user?.picture} size={32} icon={<FontAwesomeIcon icon={faUser} color="#fff" />} />}
            />
          )}
          <Button
            onClick={toggleOpenNavigator}
            className={styles.menuButton}
            type="text"
            icon={<div className={styles.burgerMenu} />}
          />
        </Space>
      </div>
      <NavigatorDrawer visible={openNavigator} onClose={toggleOpenNavigator} />
      <UserDrawer visible={openUser} onClose={toggleOpenUser} />
    </>
  );
};

export default Mobile;
