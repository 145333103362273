import { faBitcoin, faConnectdevelop } from '@fortawesome/free-brands-svg-icons';
import { faPoll, faCommentDollar, faHandshake, faGift } from '@fortawesome/free-solid-svg-icons';
import { routes } from 'types/routes';
import { HIDE_BUY_PAGE } from 'utils/constant';

let navigationRoutes = [
  { title: 'navbar.menu.buy_crypto', path: routes.buy, icon: faBitcoin },
  {
    title: 'navbar.menu.trade',
    icon: faCommentDollar,
    path: `${routes.exchange}`,
    // subs: [
    //   { title: 'Spot', path: `${routes.exchange}/BTC_USDT` },
    //   { title: 'Margin', path: `${routes.exchange}/BTC_USDT?type=cross` },
    // ],
  },
  { title: 'navbar.menu.market', path: routes.market, icon: faPoll },
  {
    title: 'navbar.menu.earn',
    icon: faHandshake,
    subs: [
      { title: 'navbar.menu.staking', path: routes.stake },
      { title: 'navbar.menu.ult_earn', path: routes.ultEarn },
    ],
  },
  {
    title: 'Campaigns',
    icon: faGift,
    subs: [
      // { title: 'SOL Giveaway', path: routes.solCampaign },
      // { title: 'ATOM 2.0', path: routes.campaign },
      { title: 'ORBS Special Page', path: routes.orbsTop10 },
    ],
  },
];

// eslint-disable-next-line no-unused-expressions
HIDE_BUY_PAGE === 1 && navigationRoutes.shift();

export { navigationRoutes };

export const walletRoutes = [
  { title: 'navbar.wallet.overview', path: routes.wallet },
  { title: 'navbar.wallet.spot_wallet', path: routes.spotWallet },
];

export const accountRoutes = [
  {
    icon: '/images/svgs/sliders-h-square-duotone.svg',
    title: 'navbar.account.account_management',
    path: routes.accountManagement,
  },
  {
    icon: '/images/svgs/conveyor-belt-alt-duotone.svg',
    title: 'navbar.account.earn_portfolio',
    path: routes.earnings,
  },
  {
    icon: '/images/svgs/gift-duotone.svg',
    title: 'navbar.account.reward_center',
    path: routes.rewardCenter,
    disabled: true,
  },
  {
    icon: '/images/svgs/users-crown-duotone.svg',
    title: 'navbar.account.referral_dashboard',
    path: routes.referral,
  },
  {
    icon: '/images/svgs/history-duotone.svg',
    title: 'navbar.account.history',
    path: routes.history,
  },
  {
    icon: '/images/svgs/plug-duotone.svg',
    title: 'navbar.account.api_keys',
    path: routes.api,
  },
  {
    icon: '/images/svgs/wallet-disabled.svg',
    title: 'navbar.account.sub_accounts',
    path: routes.subAccount,
  },
];
