import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement> & { active?: boolean }) => (
  <svg width={17} height={20} fill={props.active ? '#FFBA00' : '#9aa3a7'} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.26 6.596c0 1.22.322 1.938 1.031 2.766.537.61.709 1.393.709 2.242s-.279 1.654-.837 2.308a4.403 4.403 0 0 1-2.815 1.372c-1.525.13-3.051.24-4.598.24a40.42 40.42 0 0 1-4.597-.24 4.399 4.399 0 0 1-2.815-1.372A3.51 3.51 0 0 1 .5 11.604c0-.85.173-1.632.709-2.242.731-.828 1.032-1.547 1.032-2.766v-.413c0-1.633.407-2.7 1.245-3.745C4.734.914 6.732 0 8.707 0h.087c2.019 0 4.081.958 5.306 2.547.795 1.024 1.16 2.046 1.16 3.636v.413ZM5.91 17.53c0-.49.448-.713.863-.809.485-.102 3.442-.102 3.927 0 .415.096.864.32.864.809-.025.465-.298.877-.675 1.14a3.528 3.528 0 0 1-1.663.71 3.682 3.682 0 0 1-.978 0 3.511 3.511 0 0 1-1.663-.711c-.378-.262-.651-.674-.675-1.14Z"
      fill={props.active ? '#FFBA00' : '#9aa3a7'}
      className="two-tone-active"
    />
  </svg>
);

const NotificationIcon = memo(SvgComponent);
export default NotificationIcon;
