import { FC } from 'react';
import styles from './Footer.module.css';
import clsx from 'clsx';

import { Col, Layout as AntdLayout, Row, Space, Avatar } from 'antd';
import Link from 'next/link';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTelegramPlane, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { routes } from 'types/routes';
import { useTypeSafeTranslation } from 'hooks';
import { USER_COOKIES } from 'utils/constant';
import { getCookies } from 'utils/cookies';
import { ANNOUNCEMENT, FEEDBACK, FEES, HELP_CENTER, PRIVACY_POLICY } from './constant';
import TwitterV2 from 'assets/svgs/components/TwitterV2';

export const Footer: FC = () => {
  const { t } = useTypeSafeTranslation();
  const language = getCookies(USER_COOKIES.language);

  const getLink = (type: { [key: string]: string }) => {
    return type?.[language] || type.en;
  };

  return (
    <AntdLayout.Footer className={styles.root}>
      <div className={clsx(styles.content, 'container')}>
        <Row justify="space-between" className={styles.row}>
          <Col className={styles.logo} xs={24} md={24} lg={6}>
            <Link href="/">
              <a>
                <img src="/images/logo.svg" alt="logo" />
              </a>
            </Link>
          </Col>
          <Col xs={24} lg={18} md={24}>
            <Row gutter={[32, 32]} justify="space-between">
              <Col className={styles.col} md={8} lg={4}>
                <Space direction="vertical" align="start">
                  <Link href={routes.aboutUs}>
                    <a className={styles.link}>{t('footer.nav.about_us')}</a>
                  </Link>
                  <a href={getLink(PRIVACY_POLICY)} target="_blank" rel="noopener noreferrer">
                    <span className={styles.link}>{t('footer.nav.privacy_policy')}</span>
                  </a>
                  <Link href={routes.termsOfUse}>
                    <a>
                      <span className={styles.link}>{t('footer.nav.terms_of_use')}</span>
                    </a>
                  </Link>

                  <a
                    href="http://marketreports.ultorex.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    {t('landing_page.market.title')}
                  </a>
                  <a href={getLink(ANNOUNCEMENT)} target="_blank" rel="noopener noreferrer">
                    <span className={styles.link}>{t('footer.nav.announcements')}</span>
                  </a>
                </Space>
              </Col>
              <Col className={styles.col} md={8} lg={4}>
                <Space direction="vertical" align="start">
                  <a href={getLink(HELP_CENTER)} target="_blank" rel="noopener noreferrer">
                    <span className={styles.link}>{t('footer.nav.help_center')}</span>
                  </a>
                  <a href={getLink(FEEDBACK)} target="_blank" rel="noopener noreferrer">
                    <span className={styles.link}>{t('footer.nav.feedback')}</span>
                  </a>
                  <a href={getLink(FEES)} target="_blank" rel="noopener noreferrer">
                    <span className={styles.link}>{t('referral.other.fee')}</span>
                  </a>
                  <Link href={routes.symbol}>
                    <a>
                      <span className={styles.link}>{t('footer.coin_token')}</span>
                    </a>
                  </Link>
                  <Link href={routes.upcoming}>
                    <a>
                      <span className={styles.link}>{t('landing_page.roadmap.button')}</span>
                    </a>
                  </Link>
                </Space>
              </Col>
              {/* <Col className={styles.col} style={{ minWidth: '423px' }} lg={5}>
                <p className={styles.sectionTitle}>{t('footer.subscriber.title')}</p>
                <InputWithButton
                  placeholder={t('footer.subscriber.your_email')}
                  enterButton={t('footer.subscriber.subscribe')}
                />

                <Checkbox className="my-8">
                  {t('footer.agree_term.agree')}
                  <Link href={routes.termsOfUse}>
                    <a className="underline">{t('footer.agree_term.term')}</a>
                  </Link>
                </Checkbox>
              </Col> */}
              <Col className={styles.col} lg={6}>
                <p className={styles.sectionTitle}>{t('footer.join_community.title')}</p>
                <div className={styles.socialWrapper}>
                  <a href="https://t.me/UltorexOfficial" target="_blank" rel="noopener noreferrer">
                    <Avatar size="large" icon={<FontAwesomeIcon color="#0088CC" icon={faTelegramPlane} size="xs" />} />
                  </a>
                  <a href="https://www.facebook.com/ultorex" target="_blank" rel="noopener noreferrer">
                    <Avatar size="large" icon={<FontAwesomeIcon color="#0088CC" icon={faFacebookF} size="xs" />} />
                  </a>
                  <a href="https://twitter.com/UltorexOfficial" target="_blank" rel="noopener noreferrer">
                    <Avatar size="large" icon={<TwitterV2 />} />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UC55NqGboDRlgdjIzmYGSeqw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Avatar size="large" icon={<FontAwesomeIcon color="#FF0000" icon={faYoutube} size="xs" />} />
                  </a>
                  {/* <Avatar size="large" icon={<FontAwesomeIcon color="#5851DB" icon={faInstagram} size="xs" />} /> */}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className={clsx(styles.textBottom, 'secondary')}>
          {t('footer.ultorex', { year: dayjs().year() < 2023 ? 2023 : dayjs().year() })}
        </div>
      </div>
    </AntdLayout.Footer>
  );
};
