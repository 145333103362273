import { FC, useEffect } from 'react';
import styles from './styles.module.css';
import { LANGUAGE, USER_COOKIES } from 'utils/constant';
import { Menu, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'components/Dropdown';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { getCookies, setCookies } from 'utils/cookies';

interface LanguageSelectProps {
  desktop?: boolean;
}

export const LanguageSelect: FC<LanguageSelectProps> = ({ desktop = true }) => {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  useEffect(() => {
    const lang = getCookies(USER_COOKIES.language);
    if (LANGUAGE.findIndex((x) => x.value === lang) === -1) return;
    if (lang && lang !== locale) {
      onChangeLanguage(lang);
    }
  }, []);

  const onChangeLanguage = (lang: string) => {
    router.replace({ pathname, query }, asPath, { locale: lang || 'en' });
    setCookies(USER_COOKIES.language, lang);
  };

  const languageMenu = (
    <Menu>
      {LANGUAGE.map((lang) => (
        <Menu.Item className="uppercase" key={lang.value} onClick={() => onChangeLanguage(lang.value)}>
          {desktop ? lang.value : lang.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown className={desktop ? styles.langButton : ''} overlay={languageMenu}>
      <Space size={8} align="baseline" className={clsx('cursor default', { uppercase: desktop })}>
        {desktop ? locale : LANGUAGE.find((lang) => lang.value === locale)?.label || 'English'}
        {desktop && <FontAwesomeIcon className="primary" icon={faChevronDown} />}
      </Space>
    </Dropdown>
  );
};
