import { useCallback, useEffect, useMemo, useState } from 'react';

export const useLoadMore = <T>(initialList: T[], sizeLoad: number): [T[], any, boolean, any] => {
  // Initialize the state
  const [currentList, setCurrentList] = useState<T[]>(initialList.slice(0, sizeLoad));
  const [canLoadMore, setCanLoadMore] = useState<boolean>(initialList.length > currentList.length);

  useEffect(() => {
    setCurrentList(initialList.slice(0, sizeLoad));
    if (initialList.length > sizeLoad) {
      setCanLoadMore(true);
    }
  }, [initialList]);

  const loadMore = useCallback(() => {
    if (!canLoadMore) {
      return;
    }
    setCurrentList((pre) => {
      return [...pre, ...initialList.slice(pre.length, pre.length + sizeLoad)];
    });
    if (initialList.length > currentList.length + sizeLoad !== canLoadMore) {
      setCanLoadMore(initialList.length > currentList.length);
    }
  }, [initialList, canLoadMore, currentList]);

  return [currentList, loadMore, canLoadMore, setCurrentList];
};
